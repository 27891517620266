<template>
    <div class="blog-wrapper">
        <div class="page-header page-header-small clear-filter" filter-color="primary">
            <div class="page-header-image"></div>
            <div class="content-center">
                <div class="row" v-if="!uiModel.isLoading">
                    <div class="col-md-10 ml-auto mr-auto">
                        <h1 class="title">{{ uiModel.article.articleDetail.subject }}</h1>
                        <h4>{{ uiModel.article.articleDetail | moment("dddd, MMMM Do YYYY") }}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="section">
                <div class="container">
                    <!-- Data -->
                    <fade-transition>   
                        <div v-if="!uiModel.isLoading">                            
                            <nav aria-label="breadcrumb" role="navigation">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/blog">Blog</a></li>
                                    <li class="breadcrumb-item"><a :href="'/article?articleId=' + uiModel.article.id">Article - {{ uiModel.article.articleDetail.subject }}</a></li>
                                </ol>
                            </nav>

                            <div v-html="uiModel.article.articleDetail.body"></div>
                        </div>
                        <div v-else>
                            <div class="d-flex justify-content-center">
                                <loading-indicator></loading-indicator>
                                <div>&nbsp;Loading article content...</div>
                            </div>
                        </div>
                    </fade-transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions';
import NewsService from '@/services/newsService';

export default {
  name: 'newsarticle',
  bodyClass: 'news-article-page',
  components: {
    FadeTransition
  },
  data() {
    return {
        uiModel: {
            isLoading: true,
            article: {}
        }
    };
  },
  methods: {
      getArticleDetails() {
        var self = this;
        var newsService = new NewsService();

        self.uiModel.isLoading = true;
        newsService.getArticleDetails({
            "articleId": self.$route.params.articleid,
            "includeDetails": true
        }, self.handleArticleResponse);
      },

      handleArticleResponse(response) {
        var self = this;
        self.uiModel.isLoading = false;

        if(response.status === 200) {
            self.uiModel.article = response.data.result;
        }
      }
  },
  metaInfo() {
    return {
      title: "DrRemo | Wellness, Wherever you are - Article",
      meta: [
        {
          name: "robots",
          content: "index, follow"
        }
      ],
      link: [
        {
          rel: "canonical",
          href: "https://dr-remo.co.za/article"
        }
      ]
    }
  },
  beforeMount() {
      var self = this;
      self.getArticleDetails();
  }
};
</script>
